<template>
	<ul v-if="long">
		<li class="consultant col-12 col-lg-6">
			<div class="row align-items-center">
				<div class="col-6 col-sm-4 col-md-4 col-lg-4 mb-3 mb-sm-0 filter w-100 align-self-start">
					<b-img-lazy
						:src="square_photo || ''"
						:alt="titlePerson"
						blank-color="lightgray"
						rounded="circle"
						class="ratio-1"
						center
						block
					/>
				</div>
				<div class="col-12 col-sm-8 col-md-8 expert bio pl-sm-3">
					<div class="bio_top">
						<div class="title">
							{{ titlePerson }}
						</div>
						<p class="description">
							{{ jobTitlePerson }}
						</p>
					</div>
					<div class="bio_bottom">
						<div class="flex-column mb-2">
							<div>
								<a
									v-if="phone"
									:href="'tel:' + phone"
									class=""
								>
									{{ phone }}
								</a>
							</div>
							<div>
								<a
									v-if="email"
									:href="'emailto:' + email"
									class=""
								>
									{{ email }}
								</a>
							</div>
						</div>
					</div>
					<div class="bio_bottom">
						<div
							v-if="isConsultant &&
								(
									email
									//	facebook

									|| phone
									|| vkontakte
									|| twitter
									|| telegram
									//	|| instagram
									//	|| linkedin
									//	|| whatsapp
									|| vcard_file)"
							class="share share_link"
						>
							<div class="share_list justify-content-start">
								<!--<a
										v-if="email"
										:href="'mailto:' + email"
										target="_blank"
										class="icon-mail btn btn-primary"
										title="Email"
									/>
									<a
										v-if="phone"
										:href="'tel:' + phone"
										target="_blank"
										class="icon-phone btn btn-primary"
										title="Телефон"
									/>-->
								<!--	<a
										v-if="facebook"
										:href="'https://www.facebook.com/' + facebook"
										target="_blank"
										class="icon-fb btn btn-primary"
										title="Facebook"
									/>
								-->
								<a
									v-if="vkontakte"
									:href="'https://vk.com/' + vkontakte"
									target="_blank"
									class="icon-vk btn btn-primary"
									title="Vkontakte"
								/>
								<a
									v-if="twitter"
									:href="'https://twitter.com/' + twitter"
									target="_blank"
									class="icon-twitter btn btn-primary"
									title="Twitter"
								/>
								<a
									v-if="telegram"
									:href="'https://t.me/' + telegram"
									target="_blank"
									class="icon-telegram btn btn-primary"
									title="Telegram"
								/>
								<!--<a
										v-if="instagram"
										:href="'https://instagram.com/' + instagram"
										target="_blank"
										class="icon-instagram btn btn-primary"
										title="Instagram"
									/>

									<a
										v-if="linkedin"
										:href="'https://www.linkedin.com/in/' + linkedin"
										target="_blank"
										class="icon-linkedin btn btn-primary"
										title="LinkedIn"
									/>

									<a
										v-if="whatsapp"
										:href="'https://wa.me/' + whatsapp"
										target="_blank"
										class="icon-whatsapp btn btn-primary"
										title="WhatsApp"
									/>
								-->
								<a
									v-if="vcard_file"
									:href="vcard_file"
									class="icon-download btn btn-primary"
									title="Скачать визитку"
								/>
								<a
									v-if="isConsultant && description != null"
									href="javascript:void(null);"
									class="icon-info btn btn-primary"
									:aria-label="$t('biography')"
									:title="$t('biography')"
									@click="showModal"
								/>
							</div>
						</div>
						<!--<button v-else class="more_link btn-link text-left" @click="showModal">
								{{ isConsultant ? $t('contactConsultant') : $t('biography') }}
							</button>-->
						<b-modal
							:id="`modal-biography-${id}`"
							:title="`${titlePerson}`"
							button-size="sm"
							:centered="true"
							size="sm"
							:ok-disabled="true"
							footer-class="d-none"
							:hide-header-close="false"
							:scrollable="true"
						>
							<div v-html="description" />
						</b-modal>
					</div>
				</div>
			</div>
		</li>
	</ul>

	<div v-else class="row mx-0 align-items-center">
		<div class="col-6 col-sm-4 col-md-4 col-lg-4 mb-3 mb-sm-0 filter w-100 align-self-start">
			<b-img-lazy
				:src="square_photo || ''"
				:alt="titlePerson"
				blank-color="lightgray"
				rounded="circle"
				class="ratio-1"
				center
				block
			/>
		</div>
		<div class="col-12 col-sm-8 col-md-8 col-lg-8 expert bio pl-lg-3">
			<div class="bio_top">
				<div class="title">
					{{ titlePerson }}
				</div>
				<p class="description">
					{{ jobTitlePerson }}
				</p>
			</div>
			<div class="bio_bottom">
				<div class="flex-column mb-2">
					<div>
						<a
							v-if="phone"
							:href="'tel:' + phone"
							class=""
						>
							{{ phone }}
						</a>
					</div>
					<div>
						<a
							v-if="email"
							:href="'mailto:' + email"
							class=""
						>
							{{ email }}
						</a>
					</div>
				</div>
			</div>
			<div class="bio_bottom">
				<div
					v-if="isConsultant &&
						(
							email
							//	facebook

							|| phone
							|| vkontakte
							|| twitter
							|| telegram
							//		|| instagram
							//		|| linkedin
							//		|| whatsapp
							|| vcard_file)"
					class="share share_link"
				>
					<div class="share_list justify-content-start">
						<!--<a
									v-if="email"
									:href="'mailto:' + email"
									target="_blank"
									class="icon-mail btn btn-primary"
									title="Email"
								/>
								<a
									v-if="phone"
									:href="'tel:' + phone"
									target="_blank"
									class="icon-phone btn btn-primary"
									title="Телефон"
								/>-->
						<!--<a
									v-if="facebook"
									:href="'https://www.facebook.com/' + facebook"
									target="_blank"
									class="icon-fb btn btn-primary"
									title="Facebook"
								/>-->
						<a
							v-if="vkontakte"
							:href="'https://vk.com/' + vkontakte"
							target="_blank"
							class="icon-vk btn btn-primary"
							title="Vkontakte"
						/>
						<a
							v-if="twitter"
							:href="'https://twitter.com/' + twitter"
							target="_blank"
							class="icon-twitter btn btn-primary"
							title="Twitter"
						/>
						<a
							v-if="telegram"
							:href="'https://t.me/' + telegram"
							target="_blank"
							class="icon-telegram btn btn-primary"
							title="Telegram"
						/>
						<!--<a
									v-if="instagram"
									:href="'https://instagram.com/' + instagram"
									target="_blank"
									class="icon-instagram btn btn-primary"
									title="Instagram"
								/>
								<a
									v-if="linkedin"
									:href="'https://www.linkedin.com/in/' + linkedin"
									target="_blank"
									class="icon-linkedin btn btn-primary"
									title="LinkedIn"
								/>
								<a
									v-if="whatsapp"
									:href="'https://wa.me/' + whatsapp"
									target="_blank"
									class="icon-whatsapp btn btn-primary"
									title="WhatsApp"
								>
							</a>-->
						<a
							v-if="vcard_file"
							:href="vcard_file"
							class="icon-download btn btn-primary"
							title="Скачать визитку"
						/>
						<a
							v-if="isConsultant && description != null"
							href="javascript:void(null);"
							class="icon-info btn btn-primary"
							:aria-label="$t('biography')"
							:title="$t('biography')"
							@click="showModal"
						/>
					</div>
				</div>
				<!--<button v-else class="more_link btn-link text-left" @click="showModal">
							{{ isConsultant ? $t('contactConsultant') : $t('biography') }}
						</button>-->
			</div>
		</div>
		<b-modal
			:id="`modal-biography-${id}`"
			:title="`${titlePerson}`"
			button-size="sm"
			:centered="true"
			size="md"
			:ok-disabled="true"
			footer-class="d-none"
			:hide-header-close="false"
			:scrollable="true"
		>
			<div v-html="description" />
		</b-modal>
	</div>
</template>

<script>
const COUNT_P_FOR_SHORT_BIO = 1;

export default {
	inheritAttrs: false,
	/* eslint-disable vue/prop-name-casing */
	props: {
		id: { type: Number, required: true },
		title: { type: String, required: true },
		title_en: { type: String, required: false, default: '' },
		email: { type: String, default: null },
		phone: { type: String, default: null },
		slug: { type: String, default: '' },
		job_title: { type: String, default: '' },
		job_title_en: { type: String, default: '' },
		square_photo: { type: String, default: '' },
		description: { type: String, default: null },
		short_description: { type: String, default: null },
		long: { type: Boolean, default: false },
		itemsLength: { type: Number, default: null },
		taxonomy: { type: Object, default: () => {} },
		primary_logos: { type: Array, default: () => [] },
		language: { type: String, default: 'ru' },
		isConsultant: { type: Boolean, default: false },
		facebook: { type: String, default: null },
		vkontakte: { type: String, default: null },
		twitter: { type: String, default: null },
		telegram: { type: String, default: null },
		instagram: { type: String, default: null },
		linkedin: { type: String, default: null },
		whatsapp: { type: String, default: null },
		vcard_file: { type: String, default: null },
	},
	computed: {
		shortDescription() {
			if (!this.description) return '';

			return this.description.match(/<p.*?>.*?<\/p>/gis)
				?.filter((el, ind) => ind < COUNT_P_FOR_SHORT_BIO)
				?.join('');
		},
		logo() {
			const logoItems = this.primary_logos.filter((i) => i.src);
			if (logoItems.length === 0) return null;
			return logoItems[0];
		},
		titlePerson() {
			return this.$i18n.locale === 'ru' ? this.title : this.title_en;
		},
		jobTitlePerson() {
			return this.$i18n.locale === 'ru' ? this.job_title : this.job_title_en;
		},
	},
	methods: {
		showModal() {
			this.$bvModal.show(`modal-biography-${this.id}`);
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "/assets/styles/components/dev/lecturer";
	@import "~/assets/styles/components/home/panel-consultant";
	@import "~/assets/styles/components/home/panel-consultants";

	.ratio-1 {
		aspect-ratio: 1;
		object-fit: cover;
	}
</style>
