import { render, staticRenderFns } from "./NewPeople.vue?vue&type=template&id=3ba9a8b3&scoped=true"
import script from "./NewPeople.vue?vue&type=script&lang=js"
export * from "./NewPeople.vue?vue&type=script&lang=js"
import style0 from "./NewPeople.vue?vue&type=style&index=0&id=3ba9a8b3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba9a8b3",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton} from 'bootstrap-vue'
    installComponents(component, {BButton})
    

export default component.exports