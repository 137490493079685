<template>
	<div>
		<div class="experts_block">
			<new-person
				v-if="items.length === 1"
				:id="items[0].id"
				:title="items[0].title"
				class="experts_list row"
				v-bind="items[0]"
				long
				:is-consultant="isConsultants"
			/>
			<ul v-else class="experts_list row">
				<li v-for="person in people" :key="person.id" :class="columnClasses">
					<new-person
						:id="person.id"
						:key="person.id"
						:title="person.title"
						v-bind="person"
						:items-length="items.length"
						:is-consultant="isConsultants"
					/>
				</li>
			</ul>
		</div>
		<div
			v-if="showCount < items.length"
			class="row"
		>
			<div class="col-12 d-flex justify-content-start mt-4">
				<b-button
					variant="primary"
					@click="showCount += defaultShowCount"
				>
					{{ $t('showMore') }}
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import NewPerson from './NewPerson.vue';

export default {
	components: {
		NewPerson,
	},
	props: {
		items: { type: Array, required: true },
		isConsultants: { type: Boolean, default: false },
		shortcut: { type: Boolean, default: false },
		defaultShowCount: { type: Number, default: 3 },
	},
	data() {
		return {
			showCount: this.defaultShowCount,
		};
	},
	computed: {
		columnClasses() {
			if (this.items.length === 2) return 'consultant col-12 col-md-12 col-lg-6';
			if (this.items.length === 3) return 'consultant col-12 col-md-12 col-lg-6';
			if (this.shortcut) return 'consultant text-left col-12 col-md-12 col-lg-6';

			return 'consultant col-12 col-md-12 col-lg-6';
		},
		people() {
			return this.items.slice(0, this.showCount);
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-consultants";
</style>
