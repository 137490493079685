<template>
	<div
		:class="'panel explist ' +
			(isHeaderIndent? ' mt-0 pt-1 ' : '') +
			(isFooterIndent? ' mb-0 pb-1 ' : '') +
			block.appearance"
	>
		<div class="container">
			<div v-if="isHeaderVisible" :class="'panel_header' + (isBrand ? ' sk' : '')">
				<div class="row align-items-end">
					<div class="col-12">
						<h2 v-if="block.block_title === null && 'type_titles' in fetchedData" class="title title-lg">
							Консультанты {{ fetchedData.type_titles.title_genitive }}
						</h2>
						<h2 v-else class="title title-lg">
							{{ $i18n.locale === 'ru' ? block.block_title : block.block_title_en }}
						</h2>
						<div
							v-if="block.description"
							class="description"
							v-html="block.description"
						/>
					</div>
				</div>
			</div>

			<new-people
				v-if="block.items"
				:items="block.items"
				:is-consultants="true"
				:default-show-count="parseInt(block.default_show_count)"
			/>
			<div v-if="isFooterVisible" class="panel_footer">
				<div v-if="block.footer" class="description mb-4" v-html="block.footer" />
				<dynamic-button
					v-if="block.button"
					class="mt-4"
					:button="block.button"
					:block-id="block.id"
					:scenario="block.scenario"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';
import NewPeople from '~/components/blocks/people/NewPeople.vue';

export default {
	name: 'PanelConsultants',
	components: {
		NewPeople,
	},
	mixins: [panel],
	props: {
		fetchedData: { type: Object, required: true },
	},
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/components/home/panel-explist";
</style>
